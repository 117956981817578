import React from 'react'
import useInView from "react-cool-inview";
const moment = require('moment')
const { upperFirst } = require('lodash')

const BlogListItem = ({ post, hideSummary = false }) => {
    const { observe, inView } = useInView();
    if (post) {
        return (
            <div className="c-blog-listing__item" ref={observe}>
                <a className={inView ? "c-blog-listing__item-link is-inview" : "c-blog-listing__item-link"} href={post.url}>
                    <div className="c-blog-listing__item-media-container">
                        <div className="c-blog-listing__item-media-wrapper">
                            {post.data.listing_image.url ? <img src={`${post.data.listing_image.url}&w=500`} alt={post.data.listing_image.alt ? post.data.listing_image.alt : post.data.title.text} className="c-blog-listing__item-media" loading="lazy"/> : <img src="https://picsum.photos/320/180" alt="" className="c-blog-listing__item-media" loading="lazy"/> }
                        </div>
                        {post.tags.length ? <div className="c-blog-listing__item-category">{upperFirst(post.tags[0])}</div> : null}
                    </div>
                    <h3 className="c-blog-listing__item-title">{post.data.title.text}</h3>
                    {!hideSummary ? 
                        <p className="c-blog-listing__item-summary">{post.data.summary.text}</p> : null}                    
                    {post.data.date ? <time className="c-blog-listing__item-date" dateTime={moment(post.data.date).format("YYYY-MM-DD")}>{moment(post.data.date).format("Do MMMM YYYY")}</time> : null}
                    
                </a>
            </div>
        )
    } 
    
    return null
}

export default BlogListItem